import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import AppFooter from "../../common/AppFooter";
import { SubPageHeader, SubPageHeaderRocket } from "../styled/SubPageHeader";
import {
  addEditMobileNumberPageLoaded,
  sendCodeAction,
} from "../../store/verify-mobile-number/VerifyMobileNumberSlice";
import { RootState } from "../../store/Store";
import {
  isPhoneNumberEmpty,
  isValidMobileNumber,
  removeAllSpaces,
} from "../profile/Utils/PhoneUtils";
import { EditableMobileNumber } from "./EditableMobileNumber";
import { updateCustomerMobileField } from "../../store/customer-profile/CustomerProfileSlice";
import { Container } from "../styled/Container";
import { InputDiv } from "../styled/InputDiv";
import { LineLabel } from "../styled/LineLabel";
import { Layout } from "../styled/Layout";
import { SettingButton } from "../styled/SettingButton";
import { DeleteMobilePhoneBanner } from "./deleteMobileNumber/DeleteMobilePhoneBanner";
import { createSelector } from "@reduxjs/toolkit";
import { PageLoader } from "../common/PageLoader";
import { useFeatureFlags } from "../../common/feature-flags/UseFeatureFlags";
import { getCustomerProfileAction } from "../../store/customer-profile/CustomerProfileActions";
import {
  FormHelpText,
  StyledHideableDiv,
  VerifyButtonContainer,
  VerifyButton,
  CancelLink,
  FormHelpTextRocket,
  LineLabelRocket,
  VerifyButtonContainerRocket,
  ContainerRocket,
  ThemedButtonRocketContainer,
} from "./AddEditMobileNumberStyles";
import { useReturnUrl } from "../../common/hooks/useReturnUrl";
import { useIsPersonalCustomer } from "../profile/Utils/CustomerUtils";
import configuration from "../../config/Configuration";
import useRocketEnabled from "../../hooks/useRocketEnabled";
import {
  LinkRocket,
  SecureLoginIconRocket,
  ThemedButtonRocket,
} from "ccp-common-ui-components";

const youWillBeSentAVerificationCodeMessage = `You will be sent a one time code via SMS to keep your account secure.`;

const selectIsLoading = createSelector(
  (state: RootState) => state.verifyMobileNumber.isDeletingMobile,
  (state: RootState) => state.verifyMobileNumber.isSendingCode,
  (isDeletingMobile, isSendingCode) => isDeletingMobile || isSendingCode
);

type FormData = {
  mobile: string;
};

export interface BackButtonProps {
  backButtonUrl: string | undefined;
}

export function AddEditMobileNumberPage({ backButtonUrl }: BackButtonProps) {
  const isRocketEnabled = useRocketEnabled();
  const mobileNumberInputName = "mobile";
  const isLoading = useSelector(selectIsLoading);
  const { isBcpFeatureEnabled } = useFeatureFlags();
  const isMobileNumberDeletionAllowed = useSelector(
    (state: RootState) => state.verifyMobileNumber.isMobileNumberDeletionAllowed
  );
  const originalMobileNumber = useSelector(
    (state: RootState) => state.customerProfile.profileFields.mobile
  );
  const isMobileVerified = useSelector(
    (state: RootState) => state.customerProfile.profileFields.isMobileVerified
  );

  const [showDeleteMobileBanner, setShowDeleteMobileBanner] = useState(false);
  const mobileNumberRef = useRef(originalMobileNumber);
  const isAddMode = !originalMobileNumber;
  const subtitleMessageMode = isAddMode ? "Add" : "Edit";
  const subtitleMessage = `${subtitleMessageMode} my phone number`;

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    formState: { errors, isSubmitted, isSubmitSuccessful },
  } = useForm<FormData>();

  useEffect(() => {
    dispatch(getCustomerProfileAction());
  }, [dispatch]);

  useEffect(() => {
    register(mobileNumberInputName, {
      validate: {
        validMobileNumber: (value) => isValidMobileNumber(value),
      },
    });
  }, [originalMobileNumber, register]);

  useEffect(() => {
    mobileNumberRef.current = originalMobileNumber;
    setValue(mobileNumberInputName, originalMobileNumber);
  }, [originalMobileNumber, setValue, isSubmitSuccessful]);

  useEffect(() => {
    dispatch(addEditMobileNumberPageLoaded());
  }, [dispatch]);

  const { handleNavigation } = useReturnUrl(backButtonUrl);

  const onSubmit = (data: FormData) => {
    if (data.mobile === originalMobileNumber && isMobileVerified) {
      handleNavigation();
      return;
    }
    dispatch(updateCustomerMobileField(data.mobile));

    dispatch(
      sendCodeAction({
        mobile: data.mobile,
      })
    );
    reset();
  };

  const setMobileNumberValue = (value: string) => {
    const sanitizedPhone = removeAllSpaces(value);
    const isNotEmpty = !isPhoneNumberEmpty(sanitizedPhone);
    const shouldValidate = isSubmitted && isNotEmpty;
    setValue(mobileNumberInputName, sanitizedPhone, {
      shouldDirty: true,
      shouldValidate,
    });

    mobileNumberRef.current = sanitizedPhone;

    if (!shouldValidate) {
      clearErrors();
    }
  };
  const isPersonalCustomer = useIsPersonalCustomer();
  const isSecurityPreferenceCustomerTypeFeatureFlagOn =
    configuration.securityPreferenceCustomerType;

  const showDeleteMobileNumber =
    !isSecurityPreferenceCustomerTypeFeatureFlagOn || !isPersonalCustomer;

  const shouldShowDeleteMobileLink =
    !isAddMode &&
    !showDeleteMobileBanner &&
    isMobileNumberDeletionAllowed &&
    showDeleteMobileNumber;

  const showDeleteMobileWarning = () => {
    setShowDeleteMobileBanner(true);
  };

  return isRocketEnabled ? (
    <Layout>
      <ContainerRocket>
        <SubPageHeaderRocket className="sentry-unmask">
          <SecureLoginIconRocket size={20} /> {subtitleMessage}
        </SubPageHeaderRocket>
        <PageLoader isShown={isLoading} />
        <StyledHideableDiv isHidden={isLoading}>
          <DeleteMobilePhoneBanner
            isShown={showDeleteMobileBanner}
            backButtonUrl={backButtonUrl}
          />
          <form
            onSubmit={handleSubmit(onSubmit)}
            data-testid="add-edit-mobile-number-form"
            noValidate
          >
            <InputDiv>
              <FormHelpTextRocket role="status" className="sentry-unmask">
                To help keep your details safe, we'll send a one-time code to
                your mobile to make sure you are you.
              </FormHelpTextRocket>

              <FormHelpTextRocket role="status" className="sentry-unmask">
                Please confirm your mobile number is correct.
              </FormHelpTextRocket>

              <LineLabelRocket htmlFor="mobile-number">
                Mobile number
              </LineLabelRocket>
              <EditableMobileNumber
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                id="mobile-number"
                errorMessage={errors.mobile?.message}
                defaultValue={mobileNumberRef.current}
                {...register(mobileNumberInputName, {
                  validate: {
                    validMobileNumber: (value) => isValidMobileNumber(value),
                  },
                  onChange: (e: any) => {
                    setMobileNumberValue(e.target.value);
                  },
                })}
              />
            </InputDiv>
            <VerifyButtonContainerRocket>
              {!isBcpFeatureEnabled && (
                <ThemedButtonRocket
                  data-testid="save-button"
                  className="sentry-unmask"
                  isFullWidth={true}
                >
                  Continue
                </ThemedButtonRocket>
              )}
              <ThemedButtonRocketContainer>
                <ThemedButtonRocket
                  tabIndex={0}
                  onClick={handleNavigation}
                  className="sentry-unmask"
                  variant="link"
                  noLinkHover
                >
                  Back
                </ThemedButtonRocket>
              </ThemedButtonRocketContainer>

              {shouldShowDeleteMobileLink && (
                <LinkRocket
                  onClick={showDeleteMobileWarning}
                  data-testid="delete-mobile-number-link"
                  className="sentry-unmask"
                  label="Delete mobile number"
                  variant="primary"
                />
              )}
            </VerifyButtonContainerRocket>
          </form>
        </StyledHideableDiv>
      </ContainerRocket>
      <AppFooter />
    </Layout>
  ) : (
    <Layout>
      <SubPageHeader className="sentry-unmask">{subtitleMessage}</SubPageHeader>
      <Container>
        <PageLoader isShown={isLoading} />
        <StyledHideableDiv isHidden={isLoading}>
          <DeleteMobilePhoneBanner
            isShown={showDeleteMobileBanner}
            backButtonUrl={backButtonUrl}
          />
          <form
            onSubmit={handleSubmit(onSubmit)}
            data-testid="add-edit-mobile-number-form"
            noValidate
          >
            <InputDiv>
              <FormHelpText role="status" className="sentry-unmask">
                {youWillBeSentAVerificationCodeMessage}
              </FormHelpText>

              <LineLabel htmlFor="mobile-number">Mobile number</LineLabel>
              <EditableMobileNumber
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                id="mobile-number"
                errorMessage={errors.mobile?.message}
                defaultValue={mobileNumberRef.current}
                {...register(mobileNumberInputName, {
                  validate: {
                    validMobileNumber: (value) => isValidMobileNumber(value),
                  },
                  onChange: (e: any) => {
                    setMobileNumberValue(e.target.value);
                  },
                })}
              />
            </InputDiv>

            <VerifyButtonContainer>
              {!isBcpFeatureEnabled && (
                <VerifyButton
                  data-testid="save-button"
                  type="submit"
                  className="sentry-unmask"
                >
                  Save
                </VerifyButton>
              )}
              <CancelLink
                tabIndex={0}
                onClick={handleNavigation}
                className="sentry-unmask"
              >
                Cancel
              </CancelLink>

              {shouldShowDeleteMobileLink && (
                <SettingButton
                  onClick={showDeleteMobileWarning}
                  data-testid="delete-mobile-number-link"
                  className="sentry-unmask"
                >
                  Delete mobile number
                </SettingButton>
              )}
            </VerifyButtonContainer>
          </form>
        </StyledHideableDiv>
      </Container>
      <AppFooter />
    </Layout>
  );
}
