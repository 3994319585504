import { useEffect } from "react";
import styled from "styled-components";
import { headers } from "../../common/styles/RocketColours";
import { screenSize } from "../../common/styles/ScreenSizes";
import ProfileSettings from "./ProfileSettings";
import AppFooter from "../../common/AppFooter";
import PreferredStoreProfileSetting from "./PreferredStoreProfileSetting";
import { enablePreferredStore as isPreferredStoreEnabled } from "../../features";
import {
  CustomerProfileState,
  updateIsLoadingCustomerProfile,
} from "../../store/customer-profile/CustomerProfileSlice";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../store/Store";
import { AuthenticationStatus } from "../../store/check-authentication/CheckAuthenticationSlice";
import { getCustomerProfileAction } from "../../store/customer-profile/CustomerProfileActions";
import configuration from "../../config/Configuration";
import SecuritySettings from "./SecuritySettings";
import { endMfaPreferenceFlow } from "../../store/verify-mobile-number/VerifyMobileNumberSlice";
import {
  HideableDiv,
  Heading1Rocket,
  BodyTextRocket,
} from "ccp-common-ui-components";
import { PageLoader } from "../common/PageLoader";
import { DeleteProfileBanner } from "../../store/delete-customer-profile/DeleteProfileBanner";
import DeliveryAddressProfileSetting from "./DeliveryAddressProfileSetting";
import TeamMemberProfile from "./TeamMemberProfile/TeamMemberProfile";
import { TeamMemberNumberBanner } from "../teamMember/TeamMemberNumberBanner";
import FlybuysCard from "./Flybuys/FlybuysCard";
import { MfaPreferenceUpdateSuccessBanner } from "./MfaSetting/MfaPreferenceUpdateSuccessBanner";
import { MfaPreferenceUpdateFailedBanner } from "./MfaSetting/MfaPreferenceUpdateFailedBanner";
import { PostcodeSetting } from "./Postcode/PostcodeSetting";
import { getLoyaltyAccountsAction } from "../../store/loyalty-accounts/LoyaltyAccountsActions";
import MarketingPreferences from "./MarketingPreferences";
import ReceiptPreferences from "./ReceiptPreferences";
import { getCustomerProfilePreferencesAction } from "../../store/customer-profile-preferences/CustomerProfilePreferencesActions";
import { profilePageImpression } from "../../common/utils/tracking/profilePageTracking";
import { getQueryParameter } from "../../common/utils/NavigationHelpers";
import useRocketEnabled from "../../hooks/useRocketEnabled";
import {
  IconUserCircleOutline,
  IconWrapper,
  theme,
  rdsMargin,
  rdsPadding,
} from "@coles/rocket";

const Title = styled.h1`
  color: ${headers.black900};
  margin: 0 0 36px 0;
  font-family: "Avant Garde", "century gothic", arial, sans-serif;
  line-height: 45px;
  text-align: center;

  @media ${screenSize.mobile} {
    line-height: 35px;
  }
`;

const SubTitle = styled.h2`
  color: #000a12;
  font-family: "Avant Garde";
  line-height: 32px;
  margin: 0 0 12px 0;
  text-align: center;

  @media ${screenSize.mobile} {
    line-height: 32px;
    word-break: break-word;
  }
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 19px;
  text-align: center;

  @media ${screenSize.mobile} {
    font-size: 16px;
    line-height: 22px;
  }
`;

const ProfileContainer = styled.div`
  align-self: center;
  border-top: 2px #c7c7c7 solid;
  padding: 18px 0 32px 0;
  width: 67.5vw;
  max-width: 55em;

  @media ${screenSize.mobile} {
    width: 100%;
  }

  @media ${screenSize.tablet} {
    width: 80.5vw;
    min-width: 620px;
  }
`;

const ProfileHeader = styled.h3`
  color: ${headers.black900};
  font-family: "Avant Garde";
  font-weight: 500;
  line-height: 27px;
  margin: 0 0 24px 0;

  @media ${screenSize.mobile} {
    line-height: 32px;
  }
`;

const ProfileHeaders = styled.h3`
  color: ${headers.black900};
  font-family: "Avant Garde";
  font-weight: 500;
  line-height: 27px;
  margin: 24px 0;

  @media ${screenSize.mobile} {
    line-height: 32px;
  }
`;

const RowAlignedDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: centre;
`;

const HeadingContainerRocket = styled.div`
  ${rdsPadding.s2.left};
  ${rdsPadding.s2.bottom};
`;

const HeaderSectionRocket = styled.div<{ isLoading: Boolean }>`
  ${rdsMargin.s4.all};
  visibility: ${(props) => (props.isLoading ? "hidden" : "visible")};
`;

export interface ProfilePageProps {
  preferredStoreEnabled?: boolean;
  customerProfile: CustomerProfileState;
}

export default function ProfilePage() {
  const isRocketEnabled = useRocketEnabled();
  const dispatch = useDispatch();
  const history = useHistory();
  const customerProfile = useSelector(
    (state: RootState) => state.customerProfile
  );
  const { isLoadingCustomerProfile } = customerProfile;
  const { firstName } = customerProfile.profileFields;
  const authenticationStatus = useSelector(
    (state: RootState) => state.authentication.status
  );
  const { pendingDeletion } = customerProfile.profileFields;
  const { marketingPreferences, receiptPreferences } = useSelector(
    (state: RootState) => state.customerProfilePreferences
  );

  useEffect(() => {
    if (authenticationStatus === AuthenticationStatus.Authenticated) {
      dispatch(updateIsLoadingCustomerProfile(true));
      dispatch(getCustomerProfileAction());
      dispatch(getCustomerProfilePreferencesAction());
      dispatch(getLoyaltyAccountsAction({ returnTo: "", action: "" }));
      dispatch(endMfaPreferenceFlow());
    }
  }, [dispatch, authenticationStatus, history]);

  const returnTo =
    getQueryParameter("returnTo") ||
    `${window.location.protocol}//${window.location.host}`;

  useEffect(() => {
    if (
      customerProfile.profileFields.email &&
      marketingPreferences?.length > 0
    ) {
      profilePageImpression(
        returnTo,
        customerProfile.profileFields,
        marketingPreferences
      );
    }
  }, [returnTo, customerProfile.profileFields.email, marketingPreferences]);

  return (
    <>
      {isRocketEnabled ? (
        <HeaderSectionRocket
          isLoading={isLoadingCustomerProfile}
          data-testid="welcome-message"
        >
          <RowAlignedDiv>
            <IconWrapper
              indicator={false}
              icon={
                <IconUserCircleOutline
                  color={theme.colorForeground.secondary}
                  height="30"
                  width="30"
                />
              }
            />

            <HeadingContainerRocket>
              <Heading1Rocket data-testid="account-heading">
                Your account
              </Heading1Rocket>
            </HeadingContainerRocket>
          </RowAlignedDiv>
          <BodyTextRocket variant="body_200">
            Welcome {firstName}! you can manage your information and preferences
            here.
          </BodyTextRocket>
        </HeaderSectionRocket>
      ) : (
        <>
          <Title className="sentry-unmask">My Coles Group profile</Title>
          <HideableDiv
            isHidden={isLoadingCustomerProfile}
            data-testid="welcome-message"
          >
            <SubTitle>Welcome {firstName}!</SubTitle>
          </HideableDiv>
          <Description className="sentry-unmask">
            Here you can view and update the information stored in your profile.
          </Description>
        </>
      )}
      <ProfileContainer>
        <PageLoader isShown={isLoadingCustomerProfile} />
        <HideableDiv isHidden={isLoadingCustomerProfile} data-testid="hideable">
          <DeleteProfileBanner isShown={!!pendingDeletion} />
          <MfaPreferenceUpdateSuccessBanner />
          <MfaPreferenceUpdateFailedBanner />
          <TeamMemberNumberBanner />
          <ProfileHeader className="sentry-unmask">
            My profile information
          </ProfileHeader>
          <ProfileSettings customerProfile={customerProfile} />
          {isPreferredStoreEnabled && <PreferredStoreProfileSetting />}
          <DeliveryAddressProfileSetting />
          {configuration.enablePostcodeFeature && <PostcodeSetting />}
          {marketingPreferences &&
          marketingPreferences.length &&
          marketingPreferences.length > 0 ? (
            <>
              <ProfileHeaders>Marketing preferences</ProfileHeaders>
              <MarketingPreferences preferences={marketingPreferences} />
            </>
          ) : null}
          {receiptPreferences &&
          receiptPreferences.length &&
          receiptPreferences.length > 0 ? (
            <>
              <ProfileHeaders>Receipt preferences</ProfileHeaders>
              <ReceiptPreferences preferences={receiptPreferences} />
            </>
          ) : null}
          <>
            <ProfileHeaders className="sentry-unmask">
              Security information
            </ProfileHeaders>
            <SecuritySettings />
          </>
          <>
            <ProfileHeaders
              data-testid="linked-accounts-header"
              className="sentry-unmask"
            >
              Linked accounts
            </ProfileHeaders>
            <FlybuysCard />
            <TeamMemberProfile />
          </>
        </HideableDiv>
      </ProfileContainer>
      <AppFooter />
    </>
  );
}
